import React from 'react'
import { Icon } from 'semantic-ui-react'
import { observable, computed, action } from 'mobx'
import { Model, Store, OrderedStore, Casts } from 'store/Base'
import { snakeToCamel } from 'helpers'
import moment from 'moment'
import { omit } from 'lodash'
import { ScriptAllocationStore } from 'store/ScriptAllocation'
import { ScriptNotesStore } from 'store/ScriptNotes'

export const TYPE_ICONS = {
  environment: 'wind',
  light: 'script-type-light',
  fertilizer: 'nutrition',
  nutrition_storage: 'tank-storage',
  nutrition_irrigation: 'faucet',
  package: 'leaf',
}

export const TYPE_COLORS = {
  environment: '#388E3C',
  light: '#9562F5',
  fertilizer: '#A1887F',
  nutrition_storage: '#795548',
  nutrition_irrigation: '#2979FF',
  package: 'black',
}

export const TYPE_PREFIX = {
  environment: 'CL',
  light: 'SL',
  fertilizer: 'SF',
  nutrition_storage: 'SNS',
  nutrition_irrigation: 'SNI',
  package: 'SR',
}

export const FIELD_SUFFIX = {
  environment: {
    tIn: ' °C',
    evaporation: ' g/m²⋅h',
    speed: ' cm/s',
    directionInterval: ' min',
    co2In: ' ppm',
    deltaX: ' g/kg',
    minX: ' g/kg',
    humidity: ' %',
  },
  light: {
    intensity: ' μmol/m²/s',
  },
  fertilizer: {
    salpeterzuurA: ' L',
    kalksalpeterA: ' L',
    reserveA: ' L',
    bitterzoutA: ' L',
    fosforzuurA: ' L',
    kaliloogA: ' L',
    ijzerA: ' L',
    microElementsA: ' L',
    salpeterzuurB: ' L',
    kalksalpeterB: ' L',
    reserveB: ' L',
    bitterzoutB: ' L',
    fosforzuurB: ' L',
    kaliloogB: ' L',
    ijzerB: ' L',
    microElementsB: ' L',
  },
  nutrition_storage: {
    ec: ' mS/cm',
  },
  nutrition_irrigation: {
    pulse: ' s',
  }
}

export class Script extends Model {
  static backendResourceName = 'script'
  static omitFields = ['creator','created_at','currentlyRunning', 'lastRunningAt', 'allocations']

  static TYPES = ['environment', 'light', 'fertilizer', 'nutrition_storage', 'nutrition_irrigation']
  static STATUSES = ['concept', 'test', 'production', 'archived']
  static LOCATIONS = ['rnd', 'multilayer', 'highwire', 'germination multilayer']
  static STEPS = ['germination', 'propagation', 'transplanted', 'spaced1', 'spaced2']

  @observable id = null
  @observable type = 'environment'
  @observable status = 'concept'
  @observable location = 'rnd'
  @observable name = ''
  @observable notes = ''
  @observable repeat = 1
  @observable ordering = 0
  @observable createdAt = null
  @observable currentlyRunning = null
  @observable creator = ''
  @observable lastRunningAt = null
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')
  @observable step = null
  @observable isStepBased = false

  casts() {
    return {
      deletedAt: Casts.datetime,
    }
  }

  @computed get hasSchedule() {
    if (this.type === 'light') {
      return this.lightScript.schedules.length > 0
    } else if (this.type === 'environment') {
      return this.environmentScript.schedules.length > 0
    } else {
      return false
    }
  }

  getLabel(props = {}) {
    return super.getLabel({
      ...props,
      style: { ...props.style ?? {}, backgroundColor: TYPE_COLORS[this.type], color: '#fff' },
    })
  }

  getUrl() {
    return `/plantation/script/${this.id}/edit`
  }

  detailedHistory(){
    return this.wrapPendingRequestCount(this.api.get(`${this.url}detailed_history/`))
  }

  @computed get outOfOrder() {
    const typeModel = this[`${snakeToCamel(this.type)}Script`]
    let modelIsOutOfOrder = typeModel.schedules !== undefined &&
    typeModel.schedules.models.some((schedule, i, schedules) => (
      schedule.startTime.isBefore((i === 0 ? typeModel : schedules[i - 1]).startTime)
    ))
    return modelIsOutOfOrder
  }


  @action fixOrder() {
    const typeModel = this[`${snakeToCamel(this.type)}Script`]
    const { id, schedules, ...schedule } = typeModel.toJS()
    schedules.push(schedule)
    schedules.sort((l, r) => l.startTime.localeCompare(r.startTime))
    const [baseData] = schedules.splice(0, 1)
    typeModel.parse({ ...baseData, id, schedules })
  }

  @computed get _id() {
    return (
      <>
        <Icon name={TYPE_ICONS[this.type]} />
        {TYPE_PREFIX[this.type]}
        {this.id ?? '-'}
      </>
    )
  }

  relations() {
    return {
      lightScript: LightScript,
      fertilizerScript: FertilizerScript,
      environmentScript: EnvironmentScript,
      nutritionStorageScript: NutritionStorageScript,
      nutritionIrrigationScript: NutritionIrrigationScript,

      allocations: ScriptAllocationStore,

      parentScript: Script,
      subScripts: OrderedScriptStore,
      scriptNotes: ScriptNotesStore,
    }
  }

  toBackend(...args) {
    const res = super.toBackend(...args)

    for (const type of Script.TYPES) {
      if (type !== this.type) {
        res[`${type}_script`] = null
      }
    }

    return res
  }

  @action toBackendAll(...args) {
    const activeCurrentRelations = this.__activeCurrentRelations
    if (this.outOfOrder) {
      this.fixOrder()
    }
    const filterRelations = (
      Script.TYPES
      .filter((type) => type !== this.type )
      .map((type) => `${snakeToCamel(type)}Script`)
    )

    this.__activeCurrentRelations = activeCurrentRelations.filter((rel) => !filterRelations.includes(rel))

    try {
      return super.toBackendAll(...args)
    } finally {
      this.__activeCurrentRelations = activeCurrentRelations
    }
  }

  cloneAndAddAsSubScript() {
    return this.cloneScriptAndAddAsSubScript(this);
  }

  cloneScriptAndAddAsSubScript(script) {
    const clonedData = omit(script.toJS(), 'id', 'subScripts');

    for (const rel of script.__activeCurrentRelations) {
      clonedData[rel] = null
    }

    if(script.type === 'light'){
      clonedData.lightScript = {
        startTime: script.lightScript.startTime,
        intensity: script.lightScript.intensity,
        schedules: script.lightScript.schedules.map(script => {
          return omit(script.toJS(), 'id')
        }),
      }
    } else {
      clonedData.nutritionIrrigationScript = {
        pulse: script.nutritionIrrigationScript.pulse,
        cycleNumber: script.nutritionIrrigationScript.cycleNumber,
      }
    }

    clonedData.name = clonedData.name;

    const newSubScript = this.subScripts.add(clonedData);

    return newSubScript;
  }
}

export class ScriptStore extends Store {
  Model = Script
  static backendResourceName = 'script'
}

export const OrderedScriptStore = OrderedStore(ScriptStore, 'ordering');

export class LightScript extends Model {
  static backendResourceName = 'light_script'

  @observable id = null
  @observable intensity = 0
  @observable startTime = moment('00:00:00', 'HH:mm:ss')
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
      deletedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      schedules: LightScriptScheduleStore,
    }
  }
}

export class LightScriptSchedule extends Model {
  static backendResourceName = 'light_script_schedule'

  @observable id = null
  @observable intensity = 0
  @observable startTime = moment('00:00:00', 'HH:mm:ss')
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
      deletedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      lightScript: LightScript,
    }
  }
}

export class LightScriptScheduleStore extends Store {
  static backendResourceName = 'light_script_schedule'
  Model = LightScriptSchedule
}

export class FertilizerScript extends Model {
  static backendResourceName = 'fertilizer_script'

  @observable id = null

  @observable salpeterzuurA = 0
  @observable kalksalpeterA = 0
  @observable reserveA = 0
  @observable bitterzoutA = 0
  @observable fosforzuurA = 0
  @observable kaliloogA = 0
  @observable ijzerA = 0
  @observable microElementsA = 0

  @observable salpeterzuurB = 0
  @observable kalksalpeterB = 0
  @observable reserveB = 0
  @observable bitterzoutB = 0
  @observable fosforzuurB = 0
  @observable kaliloogB = 0
  @observable ijzerB = 0
  @observable microElementsB = 0
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')
}

export class EnvironmentScript extends Model {
  static backendResourceName = 'environment_script'

  static DIRECTIONS = ['a_to_b', 'b_to_a']

  @observable id = null
  @observable tIn = 0
  @observable evaporation = 0
  @observable speed = 0
  @observable directionInterval = 0
  @observable co2In = 0
  @observable minX = 0
  @observable humidity = 0
  @observable startTime = moment('00:00:00', 'HH:mm:ss')
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  @computed get deltaT() {
    return this.deltaX
  }

  casts() {
    return {
      startTime: Casts.momentTime,
      deletedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      schedules: EnvironmentScriptScheduleStore,
    }
  }
}

export class EnvironmentScriptSchedule extends Model {
  static backendResourceName = 'environment_script_schedule'

  @observable id = null
  @observable tIn = 0
  @observable evaporation = 0
  @observable speed = 0
  @observable directionInterval = 0
  @observable co2In = 0
  @observable minX = 0
  @observable humidity = 0
  @observable startTime = moment('00:00:00', 'HH:mm:ss')
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      startTime: Casts.momentTime,
      deletedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      environmentScript: EnvironmentScript,
    }
  }
}

export class EnvironmentScriptScheduleStore extends Store {
  static backendResourceName = 'environment_script_schedule'
  Model = EnvironmentScriptSchedule
}


export class NutritionStorageScript extends Model {
  static backendResourceName = 'nutrition_storage_script'

  @observable id = null
  @observable ec = 0
  @observable ph = 5.8
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      deletedAt: Casts.datetime,
    }
  }

  relations() {
    return {
      fertilizerScript: Script,
    }
  }
}

export class NutritionIrrigationScript extends Model {
  static backendResourceName = 'nutrition_irrigation_script'

  @observable id = null
  @observable pulse = 0
  @observable cycleNumber = 0
  @observable deleted = false
  @observable deletedAt = moment('00:00:00', 'HH:mm:ss')

  casts() {
    return {
      deletedAt: Casts.datetime,
    }
  }
}
